<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 15:33:29
 * @LastEditTime: 2021-05-27 16:33:35
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div>
    <!-- 头图 -->
    <div class="head-picture">
      <img class="cover" src="../../assets/images/topic-05.png" alt="头图" />
      <img class="mask" src="../../assets/images/topic-mask.png" alt="蒙层" />
      <div class="title">加入同汇</div>
    </div>
    <div class="sljr-container">
      <div class="sljr-render">
        <!-- 一级菜单下面所拥有的二级菜单 -->
        <el-container>
          <el-aside width="228px">
            <SideMenu :items="items"></SideMenu>
          </el-aside>

          <!-- 以及二级菜单所对应的页面 -->
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </div>
    </div>
  </div>
</template>

<script>

import SideMenu from '@/components/SideMenu/SideMenu';

export default {
  components: {
    SideMenu
  },
  data () {
    return {
      items: [
        {
          index: 'talentConcept',
          title: '人才理念'
        },
        {
          index: 'recruitInfo',
          title: '招聘信息'
        }
      ],

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.sljr-render {
}
</style>